export default {
  primary: {
    display: 'inline-block',
    backgroundColor: '#00000063',
    color: 'light',
    border: 'solid 1px',
    borderColor: 'primary',
    padding: '0.75rem 2rem',
    opacity: '0.9',
    fontSize: '1rem',
    textDecoration: 'none',
    textTransform: 'uppercase',
    ':hover': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      opacity: '0.6'
    }
  },
  secondary: {
    display: 'inline-block',
    backgroundColor: '#00000063',
    color: 'light',
    border: 'solid 1px',
    borderColor: 'primary',
    padding: '0.75rem 2rem',
    opacity: '0.9',
    fontSize: '1rem',
    textDecoration: 'none',
    textTransform: 'uppercase',
    ':hover': {
      backgroundColor: 'rgba(0,0,0,0.5)',
      opacity: '0.6'
    }
  }
}
