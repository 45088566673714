export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: '1.5rem 1rem'
    },
    '.containerScrolled': {
      backgroundColor: 'transparent',
      '.hamburger > div': {
        backgroundColor: 'white',
        filter: 'none'
      }
    },
    '.logo .image': {
      filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.logoScrolled .image': {
      filter: ['brightness(1)', '', '', 'brightness(0) invert(1)'],
      display: 'none'
    },
    '.container > .phoneContainer,.containerScrolled > .phoneContainer': {
      display: 'none!important'
    },
    '.phoneContainer': {
      display: 'none!important'
    },
    '.navMenuOpen': {
      padding: ['10vw', '15vw', '', '20vw', '25vw']
    },
    '.navMenuLogo': {
      position: ['', '', 'absolute'],
      top: ['', '', '5vh'],
      left: ['', '', '50%'],
      transform: ['', '', 'translateX(-50%)'],
      mb: '1.5rem',
      img: {
        maxHeight: ['150px', '250px'],
        height: '100%',
        width: '100%'
      }
    },
    '.navItem': {
      m: '1rem 0rem',
      a: {
        letterSpacing: ['3px', '', '5px', '7px'],
        fontSize: ['1.75rem', '', '2.5rem', '3.5rem']
      }
    },

    '.smallNavMenu': {
      display: 'none'
    },
    '.socialIconsContainerDesktop': {
      display: 'none'
    },
    '.smallNavHeading': {
      fontSize: ['1.25rem', ' 2rem']
    },
    '.socialLink': {
      margin: '0.5rem 0.5rem 0.5rem 0rem',
      svg: {
        width: '30px',
        height: '30px'
      },
      ':hover': {
        svg: {
          path: {
            fill: 'grey'
          }
        }
      }
    }
  },

  footer: {
    '.hoursContainer': {
      display: 'none!important'
    },
    '.logo': {
      filter: 'brightness(0) invert(1)'
    },
    '.address, .phone': {
      display: 'none'
    }
  },

  ctaWidget: {
    display: 'none!important'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: '100vh',
    justifyContent: 'flex-start',
    '.videoContainer': {
      height: '100vh'
    },
    '.hero_content_container': {
      display: 'none',
      position: 'absolute',
      margin: '0rem',
      width: ['90%', '', '', '60%'],
      color: 'white',
      left: '1rem',
      transform: ['translateY(-50%)', '', 'unset'],
      top: ['50%', '', 'unset'],
      bottom: ['', '', '1rem'],
      '.title': {
        fontSize: ['2rem', '3rem', '3.5rem', '4rem', '4.5rem']
      }
    },

    '.muteToggle': {
      display: 'none',
      right: '1rem',
      bottom: '1rem',
      top: 'unset',
      zIndex: '5000'
      // bottom: ['', '', '', ''],
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    '.section': {
      justifyContent: 'center',
      padding: ['2rem', '', '2rem 3rem', '2rem 4rem', '5vh 10vw'],
      maxWidth: 'unset',
      width: ['100%', '', '', '1200px']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageMenu: {},

  homepageGallery: {
    '.section': {
      justifyContent: 'center',
      padding: ['2rem', '', '2rem 3rem', '2rem 4rem', '5vh 10vw'],
      maxWidth: 'unset',
      width: ['100%', '', '', '1200px']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageContactUs: {
    '.section': {
      justifyContent: 'center',
      padding: ['2rem', '', '2rem 3rem', '2rem 4rem', '5vh 10vw'],
      maxWidth: 'unset',
      width: ['100%', '', '', '1200px']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '30%']
    },
    '.title': {
      color: 'grey',
      fontWeight: '400'
    },
    '.subtitle': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '2px',
      fontSize: '2rem',
      marginBottom: '2rem'
    },
    '.content': {
      order: ['', '', '', '1'],
      border: 'none',
      borderWidth: '5px',
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '68%'],
      margin: ['', '', '', 'auto 0 0']
    },

    // '.lazyload-wrapper::after': {
    //   content: "'Photo (c) Felix Angel'",
    // },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: '100vh'
    }
  },

  acknowledgementsTitle: {
    padding: ['1.75rem', '2rem'],
    paddingLeft: ['', '', '', '31%'],
    '.title': {
      textAlign: 'left',
      fontSize: '2rem'
    }
  },
  acknowledgementsSection1: {
    padding: '0rem 2rem 1rem',
    alignItems: 'flex-start',
    paddingLeft: ['', '', '', '31%'],
    '.section': {
      alignItems: 'flex-start',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      fontSize: '1.5rem',
      opacity: '0.8',
      textAlign: 'left'
    },
    '.text': {
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },
  acknowledgementsSection2: {
    padding: '0rem 2rem 1rem',
    alignItems: 'flex-start',
    paddingLeft: ['', '', '', '31%'],
    '.section': {
      alignItems: 'flex-start',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      fontSize: '1.5rem',
      opacity: '0.8',
      textAlign: 'left'
    },
    '.text': {
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.special-events-container > .title, .recurring-events-container > title': {
      display: 'none'
    },

    '.eventItemTitle': {
      marginBottom: '0.5rem'
    },
    '.eventItemDescription': {
      marginBottom: '1rem'
    },

    '.eventCTABtns': {
      a: {
        variant: 'buttons.primary',
        fontFamily: 'heading'
      }
    },

    '.eventItemBtn ': {
      display: 'none'
    },

    // "event orders"

    '.eventItemContainer': {
      order: '2'
    },
    '.evt-UWf52nT8S1iJx3mCDYq5Sg': {
      order: '1'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '2',
    h2: {
      textTransform: 'uppercase',
      color: 'secondary'
    },
    '.address, .phone': {
      display: 'none'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {}
}
